<template>
    <div>

        <SketchUpComponentDialog v-if="showSketchUpComponentDialog" v-bind="sketchUpComponentDialogParameters"
                                 v-on:hidden="showSketchUpComponentDialog = false"
                                 v-on:select-component="selectComponent"
                                 v-on:zoom-component="zoomComponent"
                                 v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) + ' (LP#) (' + (project ? project.label : $i18n.t('translations.No Project Found')) + ')' }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination no-state-loading autofocus :lines-per-page=35
                       :rows="filteredComponentList"
                       :fields="fields.tableFields" :actions="actions.tableActions" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:delete-selection="deleteSelection"
                       v-on:detail-components="detailComponents"
                       v-on:download-event="download"
                       v-on:generate-dxf-files="runPunchPlates"
                       v-on:refresh="getPunchPlates"
                       v-on:select-all="selectAll"
                       v-on:select-color="selectColor"
                       v-on:select-color-location="selectColorLocation"
                       v-on:select-components="selectComponents"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import FileDownload from "@/mixins/FileDownload";
import Table from "@/components/Table";
import SketchUpComponentDialog from "@/components/dialogs/SketchUpComponentDialog";

/* global sketchup:false */

export default {
    name: 'PunchPlates',
    components: {
        SketchUpComponentDialog,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            project: null,
            fields: null,
            actions: null,
            punchPlateUtilityVersion: "",
            dropBoxLocation: "",
            processedComponentList: [],
            filteredComponentList: [],
            tableReload: 0,
            filterColor: null,
            filterColorLocations: {
                matchFaceMaterial: true,
                matchFaceBackMaterial: true,
                matchComponentMaterial: true,
            },
            showSketchUpComponentDialog: false,
            sketchUpComponentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        add_color_filter_choice(choices, color, label) {
            if (color && choices.findIndex(choice => choice.value === color) === -1) {
                choices.push({
                    'icon': ['fas', 'circle'],
                    'iconsize': 'xs',
                    'iconstyle': 'color:' + color,
                    'value': color,
                    'label': label,
                });
            }
        },
        add_component_color(colors, color, label) {
            if (color && colors.filter(c => c.value === color).length === 0) {
                colors.push({
                    value: color,
                    label: label,
                });
            }
        },
        clearSelection() {
            sketchup.clearSelection();
        },
        component_matches_filter_color(component) {
            if (!this.filterColor) {
                return true;
            }
            let match = false;
            component.instances.forEach(instance => {
                if (this.instance_matches_filter_color(instance)) {
                    match = true;
                }
            });
            return match;
        },
        deleteSelection(data) {
            data.ids.forEach(id => {
                let idx = this.processedComponentList.findIndex(component => component.id === id);
                this.processedComponentList.splice(idx, 1);
            });
            this.filter();
        },
        detailComponents(component) {
            let rows = [];
            component.instances.forEach(instance => {
                let instanceIdx = rows.findIndex(row => row.entity_id === instance.entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        entity_id: instance.entityID,
                        instances: 1,
                        face_color: {
                            value: instance.face_color,
                            label: instance.face_color_name,
                        },
                        face_back_color: {
                            value: instance.face_back_color,
                            label: instance.face_back_color_name,
                        },
                        component_color: {
                            value: instance.component_color,
                            label: instance.component_color_name,
                        },
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.sketchUpComponentDialogParameters.title = component.name;
            this.sketchUpComponentDialogParameters.actions = this.actions.detailActions;
            this.sketchUpComponentDialogParameters.fields = this.fields.detailFields;
            this.sketchUpComponentDialogParameters.rows = rows;
            this.showSketchUpComponentDialog = true;
        },
        download(choice) {
            this.state.loading = true;
            let components = [];
            this.filteredComponentList.forEach(component => {
                components.push({
                    name: component.name,
                    components_in_selection: component.components_in_selection,
                    instances_in_model: component.instances_in_model,
                    instances_in_selection: component.instances_in_selection,
                    vertices: component.vertices,
                    rivets: component.rivets,
                });
            });
            this.$http.post(choice.api, {
                'components': components,
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("PunchPlates:download():error:", error);
            });
        },
        fetchData(projectId) {
            this.state.loading = true;
            this.$http.get(this.api + '/manage_punchplates/' + projectId, {}).then((res) => {
                this.project = res.data.project;
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.punchPlateUtilityVersion = res.data.punchPlateUtilityVersion;
                this.dropBoxLocation = res.data.dropBoxLocation;
                this.getPunchPlates();
                this.pageLoaded = true;
            }).catch((error) => {
                console.log("PunchPlates:fetchData():error:", error);
            });
        },
        filter() {
            this.filteredComponentList = [];
            this.processedComponentList.forEach(component => {
                if (this.component_matches_filter_color(component)) {
                    let instances_with_color = 0
                    if (this.filterColor) {
                        component.instances.forEach(instance => {
                            if (this.instance_matches_filter_color(instance)) {
                                instances_with_color++;
                            }
                        });
                    }
                    component.instances_with_color = instances_with_color;
                    this.filteredComponentList.push(component);
                }
            }, this);
            this.tableReload++;
        },
        getPunchPlates() {
            this.state.loading = true;
            sketchup.getPunchPlates();
        },
        getPunchPlatesCallback(data) {
            let rawComponentList = JSON.parse(data);
            this.processedComponentList = [];

            let colorFilterChoices = [];
            rawComponentList.forEach(component => {
                let newComponent = component;
                newComponent.id = this.processedComponentList.length + 1;
                newComponent.selected = false;

                newComponent.coordinate_system_error = '';
                if (newComponent.axes_error === 1) {
                    newComponent.coordinate_system_error = 'X';
                    newComponent.coordinate_system_error_style = "background: red; color: white;";
                }

                let ids = [];
                let colors = [];
                newComponent.instances.forEach(instance => {
                    ids.push(instance.entityID);
                    this.add_color_filter_choice(colorFilterChoices, instance.face_color, instance.face_color_name);
                    this.add_color_filter_choice(colorFilterChoices, instance.face_back_color, instance.face_back_color_name);
                    this.add_color_filter_choice(colorFilterChoices, instance.component_color, instance.component_color_name);
                    this.add_component_color(colors, instance.face_color, instance.face_color_name);
                    this.add_component_color(colors, instance.face_back_color, instance.face_back_color_name);
                    this.add_component_color(colors, instance.component_color, instance.component_color_name);
                }, this);

                newComponent.material_color_list = colors;

                newComponent.components_in_selection = [...new Set(ids)].length;
                newComponent.instances_in_selection = newComponent.instances.length;
                if (newComponent.vertices === 0) {
                    newComponent.vertices_style = "font-weight: bold; color: white; background: red;";
                }

                this.processedComponentList.push(newComponent);
            });

            this.processedComponentList.sort(function (a, b) {
                if (a.name.substring(0, 3) === 'LP#' && b.name.substring(0, 3) === 'LP#') {
                    if (parseInt(a.name.substring(3)) < parseInt(b.name.substring(3))) return -1;
                    if (parseInt(a.name.substring(3)) > parseInt(b.name.substring(3))) return 1;
                } else {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                }
                return 0;
            });

            colorFilterChoices.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
            let actionIdx = this.actions.tableActions.findIndex(action => action.name === 'select-color');
            this.actions.tableActions[actionIdx].choices = [{
                'value': null,
                'label': this.$i18n.tc('translations.No Filter on Color', 1),
            }].concat(colorFilterChoices);
            if (colorFilterChoices.filter(choice => choice.value === this.filterColor).length === 0) {
                this.filterColor = null;
            }
            this.actions.tableActions[actionIdx].selected = this.filterColor;

            this.filter();
            this.state.loading = false;
        },
        getToolDataCallback(data) {
            this.fetchData(data.project_id);
        },
        instance_matches_filter_color(instance) {
            return (
                (this.filterColorLocations.matchFaceMaterial && instance.face_color === this.filterColor) ||
                (this.filterColorLocations.matchFaceBackMaterial && instance.face_back_color === this.filterColor) ||
                (this.filterColorLocations.matchComponentMaterial && instance.component_color === this.filterColor)
            );
        },
        runPunchPlates(data) {
            this.state.loading = true;

            // XML Document
            let xml = document.implementation.createDocument(null, "punchplates");
            let elements = xml.getElementsByTagName("punchplates");

            // Options element
            let options = xml.createElement("options");
            options.setAttribute("version", this.punchPlateUtilityVersion);
            if ('polygon-offset' in data) {
                options.setAttribute("polygonOffset", data['polygon-offset']);
            } else {
                options.setAttribute("polygonOffset", '3');
            }
            if ('filename-prefix' in data) {
                options.setAttribute("fileNamePrefix", data['filename-prefix']);
            } else {
                options.setAttribute("fileNamePrefix", '');
            }
            if ('label-prefix' in data) {
                options.setAttribute("labelPrefix", data['label-prefix']);
            } else {
                options.setAttribute("labelPrefix", '');
            }
            options.setAttribute("targetDir", this.dropBoxLocation);
            elements[0].appendChild(options);

            let noRowsSelected = true;
            this.filteredComponentList.forEach(component => {
                if (component.selected) {
                    noRowsSelected = false;
                }
            });
            this.filteredComponentList.forEach(component => {
                if (noRowsSelected || component.selected) {
                    if (component.vertices > 0) {
                        // Plate element
                        let plate = xml.createElement("plate");
                        plate.setAttribute("name", component['name']);
                        plate.setAttribute("quantity", component['instances_in_selection']);

                        // Face element
                        let face = xml.createElement("face");
                        component['face'].forEach(facePoint => {
                            let point = xml.createElement("point");
                            point.setAttribute("x", facePoint.x);
                            point.setAttribute("y", facePoint.y);
                            face.appendChild(point);
                        });
                        plate.appendChild(face);

                        // Holes element
                        let holes = xml.createElement("holes");
                        component['holes'].forEach(holePoint => {
                            let hole = xml.createElement("hole");
                            hole.setAttribute("x", holePoint.x);
                            hole.setAttribute("y", holePoint.y);
                            hole.setAttribute("d", holePoint.d);
                            holes.appendChild(hole);
                        });
                        plate.appendChild(holes);

                        elements[0].appendChild(plate);
                    }
                }
            });

            let sXml = (new XMLSerializer()).serializeToString(xml);
            sketchup.runPunchPlates(process.env.VUE_APP_TEMP_DIR, process.env.VUE_APP_PUNCHPLATES_DIR, sXml);
        },
        runPunchPlatesCallback() {
            this.state.loading = false;
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            sketchup.selectComponent(component.entity_id);
        },
        selectComponents(component) {
            let ids = [];
            component.instances.forEach(instance => {
                if (!this.filterColor || this.instance_matches_filter_color(instance)) {
                    ids.push(instance.entityID);
                }
            }, this);
            sketchup.selectComponents(ids);
        },
        selectColor(choice) {
            this.filterColor = choice.value;
            this.filter();
        },
        selectColorLocation(choices) {
            choices.forEach(choice => {
                this.filterColorLocations[choice.name] = choice.value;
            });
            this.filter();
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            if ('entity_id' in component) {
                sketchup.zoomExtents(component.entity_id);
            } else {
                let ids = [];
                component.instances.forEach(instance => {
                    if (!this.filterColor || this.instance_matches_filter_color(instance)) {
                        ids.push(instance.entityID);
                    }
                }, this);
                sketchup.zoomExtents(ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>
